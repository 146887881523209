var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.show,"width":"50%"},on:{"click:outside":function($event){return _vm.$emit('cancel')}}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"dark":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"white--text font-weight-black"},[_vm._v(" Adicionar/Editar Contato ")])],1),_c('v-card-text',{staticClass:"mt-4"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Nome","error-messages":errors},model:{value:(_vm.newContact.name),callback:function ($$v) {_vm.$set(_vm.newContact, "name", $$v)},expression:"newContact.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"nickname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Apelido","error-messages":errors},model:{value:(_vm.newContact.nickname),callback:function ($$v) {_vm.$set(_vm.newContact, "nickname", $$v)},expression:"newContact.nickname"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"E-mail","error-messages":errors},model:{value:(_vm.newContact.email),callback:function ($$v) {_vm.$set(_vm.newContact, "email", $$v)},expression:"newContact.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Telefone","error-messages":errors},model:{value:(_vm.newContact.telephone),callback:function ($$v) {_vm.$set(_vm.newContact, "telephone", $$v)},expression:"newContact.telephone"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Cargo","error-messages":errors},model:{value:(_vm.newContact.position),callback:function ($$v) {_vm.$set(_vm.newContact, "position", $$v)},expression:"newContact.position"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"sector"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Setor","error-messages":errors},model:{value:(_vm.newContact.sector),callback:function ($$v) {_vm.$set(_vm.newContact, "sector", $$v)},expression:"newContact.sector"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"signature"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","accept":"image/*","hide-details":"auto","label":"Assinatura","hint":"Tamanho ideal: 90x45","persistent-hint":"","error-messages":errors},model:{value:(_vm.newContact.signature),callback:function ($$v) {_vm.$set(_vm.newContact, "signature", $$v)},expression:"newContact.signature"}})]}}])}),_c('v-img',{attrs:{"max-width":"100px","src":_vm.newContact.signatureBase64}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-spacer'),_c('btn-cancel',{staticClass:"mr-1",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('btn-save',{attrs:{"loading":_vm.loadingSave,"text":""},on:{"click":function($event){return _vm.$emit('save', _vm.newContact)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }