<template>
  <v-dialog
    :value="show"
    width="50%"
    @click:outside="$emit('cancel')"
  >
    <v-card
      elevation="0"
    >
      <v-toolbar
        dark
        flat
        dense
      >
        <v-toolbar-title class="white--text font-weight-black">
          Adicionar/Editar Contato
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-4">
        <validation-observer ref="observer">
          <v-row>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="newContact.name"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="nickname"
              >
                <v-text-field
                  v-model="newContact.nickname"
                  hide-details="auto"
                  label="Apelido"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="email"
              >
                <v-text-field
                  v-model="newContact.email"
                  hide-details="auto"
                  label="E-mail"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="telephone"
              >
                <v-text-field
                  v-model="newContact.telephone"
                  hide-details="auto"
                  label="Telefone"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="position"
              >
                <v-text-field
                  v-model="newContact.position"
                  hide-details="auto"
                  label="Cargo"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="sector"
              >
                <v-text-field
                  v-model="newContact.sector"
                  hide-details="auto"
                  label="Setor"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="signature"
              >
                <v-file-input
                  v-model="newContact.signature"
                  prepend-icon=""
                  accept="image/*"
                  hide-details="auto"
                  label="Assinatura"
                  hint="Tamanho ideal: 90x45"
                  persistent-hint
                  :error-messages="errors"
                />
              </validation-provider>

              <v-img
                max-width="100px"
                :src="newContact.signatureBase64"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>

      <v-divider class="mt-4" />
      <v-card-actions>
        <v-spacer />
        <btn-cancel
          class="mr-1"
          text
          @click="$emit('cancel')"
        />
        <btn-save
          :loading="loadingSave"
          text
          @click="$emit('save', newContact)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {

    props: {
      show: {
        type: Boolean,
        default: false,
      },
      contact: {
        type: Object,
        default: () => { return {} },
      },
      formValidation: {
        type: Object,
        default: () => { return {} },
      },
      loadingSave: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        newContact: {

        },
      }
    },

    watch: {
      formValidation (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      contact (contact) {
        this.newContact = contact
      },
    },

    methods: {

    },

  }
</script>
